import types from "./types";
import { cloneDeep } from 'lodash';


const initialState = {
  updateDoctor: false
};

export const rootReducer = (state = initialState, action) => {
  const appState = cloneDeep(state);
  switch (action.type) {
    case types.USER_AUTHENTICATED:
      return { ...appState, isUserAuthenticated: action.payload };
    default:
      return state;
  }
};
