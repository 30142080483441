import types from "./types";


/**
 * Notify components about doctor update
 * @param {boolean} authenticated
 * @return {{payload: boolean, type: string}}
 */
export const notifyUserAuthenticated = authenticated => {
  return {
    type: types.USER_AUTHENTICATED,
    payload: authenticated
  }
}
