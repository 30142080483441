import React, { useEffect, useState } from "react";
import PageHeader from "../../components/page-header";
import moment from "moment";
import { Button, Modal, Table } from "react-bootstrap";
import AddNotificationDialog from "../../components/add-notification";
import { api, globalErrorHandler } from "../../index";
import { useDispatch } from "react-redux";

const NotificationsPage = props => {
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState([]);
  const [deleteDialog, setDeleteDialog] = useState({ show: false, notification: null });
  const [addNotification, setAddNotification] = useState(false)

  useEffect(() => {
    fetchNotifications()
  }, [props]);

  const hideDeleteDialog = () => {
    setDeleteDialog({ show: false, notification: null });
  }

  const fetchNotifications = () => {
    api().get("/notifications").then(response => {
      setNotifications(response.data);
    }).catch(error => dispatch(globalErrorHandler(error)));
  }

  const deleteNotification = () => {
    hideDeleteDialog()
    const { notification } = deleteDialog;
    api().delete(`/notifications/${notification.id}`)
      .then(fetchNotifications)
      .catch(error => dispatch(globalErrorHandler(error)));
  }

  const sendNotification = notification => {
    const formData = new FormData()
    formData.append("title", notification.title);
    formData.append("message", notification.message);
    api().post("/notifications", formData)
      .then(fetchNotifications)
      .catch(error => dispatch(globalErrorHandler(error)));
  };

  const handleDeleteClick = (event, notification) => {
    event.stopPropagation();
    setDeleteDialog({ show: true, notification });
  }

  const handleAddNotificationClick = () => {
    setAddNotification(true)
  }

  const hideAddNotification = () => {
    setAddNotification(false);
  }

  return (
    <div>
      {deleteDialog.show && (
        <Modal
          centered
          show={deleteDialog.show}
          onHide={hideDeleteDialog}
        >
          <Modal.Header>
            Удалить уведомление?
          </Modal.Header>
          <Modal.Footer>
            <Button onClick={hideDeleteDialog}>Нет</Button>
            <Button variant="outline-danger" onClick={deleteNotification}>Да</Button>
          </Modal.Footer>
        </Modal>
      )}
      {addNotification && (
        <AddNotificationDialog onHide={hideAddNotification} show={addNotification} onSave={sendNotification}/>
      )}
      <PageHeader title="Уведомления" actionText="Отправить уведомление" onActionClick={handleAddNotificationClick}/>
      <Table striped bordered hover>
        <thead>
        <tr>
          <th>#</th>
          <th align="left">Заголовок</th>
          <th>Сообщение</th>
          <th>Дата отправки</th>
          <th>Действия</th>
        </tr>
        </thead>
        <tbody>
        {notifications.map((notification, index) => (
          <tr key={notification.id}>
            <td>{index + 1}</td>
            <td align="left">{notification.title}</td>
            <td>{notification.message}</td>
            <td>{moment(notification.created).format("DD MMM YYYY")}</td>
            <td align="center">
              <Button
                variant="none"
                onClick={event => handleDeleteClick(event, notification)}
                style={{ display: 'flex', alignItems: 'center', padding: 0 }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#dc3545">
                  <path d="M0 0h24v24H0V0z" fill="none"/>
                  <path
                    d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zm2.46-7.12l1.41-1.41L12 12.59l2.12-2.12 1.41 1.41L13.41 14l2.12 2.12-1.41 1.41L12 15.41l-2.12 2.12-1.41-1.41L10.59 14l-2.13-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4z"/>
                  <path d="M0 0h24v24H0z" fill="none"/>
                </svg>
              </Button>
            </td>
          </tr>
        ))}
        </tbody>
      </Table>
    </div>
  )
};

export default NotificationsPage
