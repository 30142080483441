import React from 'react';
import MainMenu from "../../components/main-menu";
import { Switch, Route } from 'react-router-dom';
import MainPage from "../main";
import CategoriesPage from "../categories";
import "./styles.scss"
import PresetsPage from "../presets";
import UsersPage from "../users";
import NotificationsPage from "../notifications";
import UserEdits from "../user-edits";

const BasePage = props => {
  return (
    <div className="base-page-root">
      <MainMenu/>
      <div className="data-container">
        <Switch>
          <Route path="/" exact component={MainPage}/>
          <Route path="/categories" component={CategoriesPage}/>
          <Route path="/presets" component={PresetsPage}/>
          <Route path="/user-edits" component={UserEdits}/>
          <Route path="/users" component={UsersPage}/>
          <Route path="/notifications" component={NotificationsPage}/>
        </Switch>
      </div>
    </div>
  )
};

export default BasePage
