import React, { useEffect, useRef, useState } from "react";
import PageHeader from "../../components/page-header";
import { api, globalErrorHandler } from "../../index";
import { Button, Modal, Table } from "react-bootstrap";
import "./styles.scss"
import AddUserPhoto from "../../components/add-photo";
import { useDispatch } from "react-redux";

const UserEdits = props => {
  const dispatch = useDispatch();
  const presets = useRef([]);
  const [usersPhoto, setUsersPhotos] = useState([]);
  const [photoDetails, setPhotoDetails] = useState({ show: false, photo: null });
  const [deleteDialog, setDeleteDialog] = useState({ show: false, photo: null });

  useEffect(() => {
    fetchUserPhotos();
  }, [props]);

  const fetchPresets = () => {
    api().get("/presets").then(response => {
      presets.current = response.data
    }).catch(error => dispatch(globalErrorHandler(error)));
  };

  const fetchUserPhotos = () => {
    api().get("/user-edits").then(response => {
      setUsersPhotos(response.data);
      fetchPresets();
    }).catch(error => dispatch(globalErrorHandler(error)));
  };

  const getImageName = category => {
    const parts = category.split("/");
    return parts[parts.length - 1];
  };

  const showUserPhotoDetails = (show, photo) => {
    setPhotoDetails({ show, photo });
  };

  const editPhoto = (data, photoId) => {
    api().put(`/user-edits/${photoId}`, data)
      .then(fetchUserPhotos)
      .catch(error => dispatch(globalErrorHandler(error)));
  };

  const createPhoto = data => {
    api().post("/user-edits", data)
      .then(fetchUserPhotos)
      .catch(error => dispatch(globalErrorHandler(error)));
  };

  const handlePhotoSaved = photo => {
    const formData = new FormData();
    formData.append("instagramId", `@${photo.instagramId}`);
    photo.avatar && formData.append("avatar", photo.avatar, photo.avatar.name);
    photo.image && formData.append("image", photo.image, photo.image.name);
    formData.append("presetId", photo.presetId);

    if (photo.id != null) {
      editPhoto(formData, photo.id)
    } else {
      createPhoto(formData);
    }
  };

  const deletePhoto = () => {
    const { photo } = deleteDialog;
    setDeleteDialog({ show: false, photo: null });
    api().delete(`/user-edits/${photo.id}`)
      .then(fetchUserPhotos)
      .catch(error => dispatch(globalErrorHandler(error)));
  };

  const handleDeleteClick = (event, photo) => {
    event.stopPropagation();
    setDeleteDialog({ show: true, photo });
  };

  const hideDeleteDialog = () => {
    setDeleteDialog({ show: false, photo: null });
  };

  return (
    <div>
      {deleteDialog.show && (
        <Modal
          centered
          show={deleteDialog.show}
          onHide={hideDeleteDialog}
        >
          <Modal.Header>
            Удалить фото?
          </Modal.Header>
          <Modal.Footer>
            <Button onClick={hideDeleteDialog}>Нет</Button>
            <Button variant="outline-danger" onClick={deletePhoto}>Да</Button>
          </Modal.Footer>
        </Modal>
      )}
      {photoDetails.show && (
        <AddUserPhoto
          onSave={handlePhotoSaved}
          onHide={() => showUserPhotoDetails(false, null)}
          show={photoDetails.show}
          presets={presets.current}
          photo={photoDetails.photo}
        />
      )}
      <PageHeader
        title="Фото пользователей"
        actionText="Добавить +"
        onActionClick={() => showUserPhotoDetails(true, null)}
      />
      <Table striped bordered hover>
        <thead>
        <tr>
          <th>#</th>
          <th align="left">Инстаграм</th>
          <th>Пресет</th>
          <th>Изображение</th>
          <th>Аватар</th>
          <th>Действия</th>
        </tr>
        </thead>
        <tbody>
        {usersPhoto.map((photo, index) => (
          <tr key={photo.id} onClick={() => showUserPhotoDetails(true, photo)}>
            <td>{index + 1}</td>
            <td align="left">{photo.instagramId}</td>
            <td>{photo.preset.name}</td>
            <td>
              <a
                href={photo.image}
                onClick={e => e.stopPropagation()}
                target="_blank"
              >
                {getImageName(photo.image)}
              </a>
            </td>
            <td>
              <a
                href={photo.avatar}
                onClick={e => e.stopPropagation()}
                target="_blank"
              >
                {getImageName(photo.avatar)}
              </a>
            </td>
            <td align="center">
              <Button
                variant="none"
                onClick={event => handleDeleteClick(event, photo)}
                style={{ display: 'flex', alignItems: 'center', padding: 0 }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#dc3545">
                  <path d="M0 0h24v24H0V0z" fill="none"/>
                  <path
                    d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zm2.46-7.12l1.41-1.41L12 12.59l2.12-2.12 1.41 1.41L13.41 14l2.12 2.12-1.41 1.41L12 15.41l-2.12 2.12-1.41-1.41L10.59 14l-2.13-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4z"/>
                  <path d="M0 0h24v24H0z" fill="none"/>
                </svg>
              </Button>
            </td>
          </tr>
        ))}
        </tbody>
      </Table>
    </div>
  )
};

export default UserEdits
