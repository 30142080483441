import React from "react";
import PropTypes from 'prop-types';
import { FormControl } from "react-bootstrap";
import { cloneDeep } from 'lodash';
import "./styles.scss"

const TagsInput = props => {
  const { onChange, selectedTags } = props;
  const [tags, setTags] = React.useState(selectedTags);

  const addTags = event => {
    if (event.key === "Enter" && event.target.value !== "") {
      const newTags = cloneDeep(tags);
      newTags.push(event.target.value);
      setTags(newTags);
      event.target.value = "";
      onChange(newTags);
    }
  }

  const removeTags = index => {
    const newTags = cloneDeep(tags);
    newTags.splice(index, 1)
    setTags(newTags);
    onChange(newTags);
  }

  return (
    <div className="tags-input">
      <FormControl
        className="tags-field"
        type="text"
        onKeyUp={addTags}
        placeholder="Нажмите Enter чтобы добавить таг"
      />
      <div className="tags-list">
        {tags.map((tag, index) => (
          <div key={index} className="tag-item">
            <span style={{ marginRight: 5 }}>{tag}</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="20"
              viewBox="0 0 24 24"
              width="20"
              fill="#fff"
              onClick={() => removeTags(index)}
            >
              <path d="M0 0h24v24H0V0z" fill="none"/>
              <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zm2.46-7.12l1.41-1.41L12 12.59l2.12-2.12 1.41 1.41L13.41 14l2.12 2.12-1.41 1.41L12 15.41l-2.12 2.12-1.41-1.41L10.59 14l-2.13-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4z"/>
              <path d="M0 0h24v24H0z" fill="none"/>
            </svg>
          </div>
        ))}
      </div>
    </div>
  );
};

TagsInput.propTypes = {
  onChange: PropTypes.func,
  selectedTags: PropTypes.arrayOf(PropTypes.string),
}

TagsInput.defaultProps = {
  onChange: () => null,
  selectedTags: [],
}

export default TagsInput;
