import React, { useEffect, useState } from "react";
import PageHeader from "../../components/page-header";
import moment from "moment";
import { Button, Modal, Table } from "react-bootstrap";
import { api, globalErrorHandler } from "../../index";
import { useDispatch } from "react-redux";

const UsersPage = props => {
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [deleteDialog, setDeleteDialog] = useState({ show: false, user: null });

  useEffect(() => {
    fetchUsers()
  }, [props]);

  const fetchUsers = () => {
    api().get("/users").then(response => {
      setUsers(response.data);
    }).catch(error => dispatch(globalErrorHandler(error)));
  };

  const hideDeleteDialog = () => {
    setDeleteDialog({ show: false, user: null });
  }

  const deleteUser = () => {
    const { user } = deleteDialog;
    api().delete(`/users/${user.id}`).then(() => {
      fetchUsers()
    }).catch(error => dispatch(globalErrorHandler(error)));
    hideDeleteDialog()
  }

  const handleDeleteClick = (event, user) => {
    console.log(user)
    event.stopPropagation()
    setDeleteDialog({ show: true, user: user });
  }

  return (
    <div>
      {deleteDialog.show && (
        <Modal
          centered
          show={deleteDialog.show}
          onHide={hideDeleteDialog}
        >
          <Modal.Header>
            Удалить пользователя?
          </Modal.Header>
          <Modal.Footer>
            <Button onClick={hideDeleteDialog}>Нет</Button>
            <Button variant="outline-danger" onClick={deleteUser}>Да</Button>
          </Modal.Footer>
        </Modal>
      )}
      <PageHeader title="Пользователи"/>
      <Table striped bordered hover>
        <thead>
        <tr>
          <th>#</th>
          <th align="left">ID</th>
          <th>Дата регистрации</th>
          <th>Действия</th>
        </tr>
        </thead>
        <tbody>
        {users.map((user, index) => (
          <tr key={user.id}>
            <td>{index + 1}</td>
            <td align="left">{user.userId}</td>
            <td>{moment(user.created).format("DD MMM YYYY")}</td>
            <td align="center">
              <Button
                variant="none"
                onClick={event => handleDeleteClick(event, user)}
                style={{ display: 'flex', alignItems: 'center', padding: 0 }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#dc3545">
                  <path d="M0 0h24v24H0V0z" fill="none"/>
                  <path
                    d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zm2.46-7.12l1.41-1.41L12 12.59l2.12-2.12 1.41 1.41L13.41 14l2.12 2.12-1.41 1.41L12 15.41l-2.12 2.12-1.41-1.41L10.59 14l-2.13-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4z"/>
                  <path d="M0 0h24v24H0z" fill="none"/>
                </svg>
              </Button>
            </td>
          </tr>
        ))}
        </tbody>
      </Table>
    </div>
  )
};

export default UsersPage
