import React, { useEffect, useState } from "react";
import "./styles.scss";
import PageHeader from "../../components/page-header";
import { Button, Modal, Table } from "react-bootstrap";
import moment from "moment";
import AddCategoryDialog from "../../components/add-category";
import { api, BASE_URL, globalErrorHandler } from "../../index";
import { useDispatch } from "react-redux";

const CategoriesPage = props => {
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const [showDetails, setShowDetails] = useState({ show: false, category: null });
  const [showDeleteDialog, setShowDeleteDialog] = useState({ show: false, category: null });

  useEffect(() => {
    fetchCategories();
  }, [props]);

  const fetchCategories = () => {
    api().get("/categories").then(response => {
      setCategories(response.data);
    }).catch(error => dispatch(globalErrorHandler(error)));
  };

  const showCategoryDetails = (show, category) => {
    setShowDetails({ show: show, category: category });
  };

  const getFormattedDate = (initialDate) => {
    return moment(initialDate).format("DD MMM YYYY");
  };

  const createCategory = category => {
    const formData = new FormData();
    formData.append("title", category.title);
    formData.append("tags", category.tags);
    formData.append("categoryImage", category.image, category.image.name);
    api().post("/categories", formData).then(response => {
      fetchCategories();
    }).catch(error => dispatch(globalErrorHandler(error)));
  };

  const editCategory = category => {
    const formData = new FormData();
    formData.append("title", category.title);
    formData.append("tags", category.tags);
    category.image && formData.append("categoryImage", category.image, category.image.name);
    api().put(`/categories/${category.id}`, formData).then(response => {
      fetchCategories();
    }).catch(error => dispatch(globalErrorHandler(error)));
  };

  const handleCategorySave = category => {
    if (!category.id) createCategory(category);
    else editCategory(category);
  };

  const getImageName = category => {
    const parts = category?.split("/");
    if (parts == null || parts.length === 0) {
      return ''
    }
    return parts[parts.length - 1];
  }

  const handleDeleteClick = (event, category) => {
    event.stopPropagation();
    setShowDeleteDialog({ show: true, category });
  }

  const hideDeleteDialog = () => {
    setShowDeleteDialog({ show: false, category: null })
  }

  const deleteCategory = () => {
    hideDeleteDialog();
    const { category } = showDeleteDialog;
    if (!category) return;
    api().delete(`/categories/${category.id}`)
      .then(() => fetchCategories())
      .catch(error => dispatch(globalErrorHandler(error)));
  };

  return (
    <div className="categories-root">
      {showDeleteDialog.show && (
        <Modal
          centered
          show={showDeleteDialog.show}
          onHide={hideDeleteDialog}
        >
          <Modal.Header>
            Удалить категорию?
          </Modal.Header>
          <Modal.Footer>
            <Button onClick={hideDeleteDialog}>Нет</Button>
            <Button variant="outline-danger" onClick={deleteCategory}>Да</Button>
          </Modal.Footer>
        </Modal>
      )}
      {showDetails.show && (
        <AddCategoryDialog
          show={showDetails.show}
          category={showDetails.category}
          onSave={handleCategorySave}
          onHide={() => showCategoryDetails(false, null)}
        />
      )}
      <PageHeader
        title="Категории"
        actionText="Добавить +"
        onActionClick={() => showCategoryDetails(true, null)}
      />
      <Table striped bordered hover>
        <thead>
        <tr>
          <th>#</th>
          <th align="left">Название</th>
          <th>Изображение</th>
          <th>Пресеты</th>
          <th>Создано</th>
          <th>Действия</th>
        </tr>
        </thead>
        <tbody>
        {categories.map((category, index) => (
          <tr key={category.id} onClick={() => showCategoryDetails(true, category)}>
            <td>{index + 1}</td>
            <td align="left">{category.title}</td>
            <td>
              <a
                href={category.image}
                onClick={e => e.stopPropagation()}
                target="_blank"
              >
                {getImageName(category.image)}
              </a>
            </td>
            <td>{category.presets}</td>
            <td align="center">
              <span>{getFormattedDate(category.created)}</span>
            </td>
            <td align="center">
              <Button
                variant="none"
                onClick={event => handleDeleteClick(event, category)}
                style={{ display: 'flex', alignItems: 'center', padding: 0 }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#dc3545">
                  <path d="M0 0h24v24H0V0z" fill="none"/>
                  <path
                    d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zm2.46-7.12l1.41-1.41L12 12.59l2.12-2.12 1.41 1.41L13.41 14l2.12 2.12-1.41 1.41L12 15.41l-2.12 2.12-1.41-1.41L10.59 14l-2.13-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4z"/>
                  <path d="M0 0h24v24H0z" fill="none"/>
                </svg>
              </Button>
            </td>
          </tr>
        ))}
        </tbody>
      </Table>
    </div>
  )
};

export default CategoriesPage
