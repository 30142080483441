import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import './App.css';
import BasePage from "../pages/base";
import { createBrowserHistory } from "history";
import AuthPage from "../pages/auth";
import { KEY_IS_LOGGED_ID } from "../index";
import { useSelector } from "react-redux";

export const history = createBrowserHistory({
  baseName: process.env.PUBLIC_URL
})

function App() {
  const isUserAuthenticated = useSelector(state => state.isUserAuthenticated);

  const isLoggedIn = () => {
    return localStorage.getItem(KEY_IS_LOGGED_ID) === "1"
  }

  return (
    <div className="App">
      <Router basename={'/'}>
        {!isLoggedIn() ? <Redirect to="/login"/> : <Redirect to="/"/>}
        <Switch>
          <Route path="/login" exact component={AuthPage}/>
          <Route path="/" component={BasePage}/>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
