import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider, useDispatch } from 'react-redux';
import { rootReducer } from "./redux/reducers";
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from "axios";
import moment from "moment";
import "moment/locale/ru";
import { notifyUserAuthenticated } from "./redux/actions";

const middlewareEnhancer = compose(applyMiddleware(thunk));
const store = createStore(rootReducer, undefined, middlewareEnhancer);

export const BASE_URL = "https://api.colorset.app"
// export const BASE_URL = "http://localhost:8000"

export const KEY_IS_LOGGED_ID = "IsUserAuthenticated"
export const KEY_AUTH_TOKEN = "UserAuthToken"

export const globalErrorHandler = error => (dispatch, getState) => {
  if (error.response != null && error.response.data.status === 401) {
    localStorage.removeItem(KEY_AUTH_TOKEN);
    localStorage.setItem(KEY_IS_LOGGED_ID, "0");
    dispatch(notifyUserAuthenticated(false));
  }
  console.error(error);
}

export const api = () => {
  let requestHeaders = {};
  const token = localStorage.getItem(KEY_AUTH_TOKEN);
  if (token && token.length > 0)
    requestHeaders['Authorization'] = token;
  return axios.create({
    baseURL: BASE_URL,
    headers: requestHeaders,
  });
};

moment.locale("ru")

ReactDOM.render(
  <Provider store={store}>
    <App/>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
