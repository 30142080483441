import React, { useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import "./styles.scss";

const getFileName = photo => {
  const parts = photo.split("/");
  return parts[parts.length - 1];
}

const AddUserPhoto = ({ onSave, onHide, photo, show, presets }) => {
  const [instagramId, setInstagramId] = useState(photo ? photo.instagramId.replace("@", "") : "");
  const [avatarFile, setAvatarFile] = useState(photo ? { name: getFileName(photo.avatar) } : null);
  const [photoFile, setPhotoFile] = useState(photo ? { name: getFileName(photo.image) } : null);
  const [preset, setPreset] = useState(photo ? photo.preset : presets.length > 0 ? presets[0] : null);

  const handleInstagramIdChange = event => {
    setInstagramId(event.target.value);
  }

  const handlePhotoFileChanged = event => {
    setPhotoFile(event.target.files[0]);
  }

  const handleAvatarChanged = event => {
    setAvatarFile(event.target.files[0]);
  }

  const handlePresetChanged = event => {
    const preset = presets.find(it => it.id === parseInt(event.target.value))
    setPreset(preset);
  }

  const isFormValid = () => {
    return (instagramId && instagramId.length > 0 && avatarFile != null && photoFile != null && preset != null);
  }

  const handlePhotoSave = () => {
    onSave({
      id: photo ? photo.id : null,
      instagramId: instagramId,
      avatar: avatarFile.constructor === File ? avatarFile : null,
      image: photoFile.constructor === File ? photoFile : null,
      presetId: preset.id,
    });

    onHide()
  }

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header>
        <span>Детали фото</span>
      </Modal.Header>

      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Instagram ID</Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                placeholder="Instagram ID ..."
                value={instagramId}
                onChange={handleInstagramIdChange}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group>
            <Form.File
              custom
              type="file"
              accept=".png,.jpg,.jpeg"
              label={photoFile ? photoFile.name : "Выберите изображение"}
              onChange={handlePhotoFileChanged}
            />
          </Form.Group>
          <Form.Group>
            <Form.File
              custom
              type="file"
              accept=".png,.jpg,.jpeg"
              label={avatarFile ? avatarFile.name : "Выберите аватар"}
              onChange={handleAvatarChanged}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Выберите пресет</Form.Label>
            <Form.Control
              as="select"
              custom
              onChange={handlePresetChanged}
              value={preset ? preset.id : ""}
            >
              {presets.map(preset => (
                <option
                  value={preset.id}
                  key={preset.id}
                  id={preset.id}
                >
                  {preset.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outline-danger" onClick={onHide}>Закрыть</Button>
        <Button variant="success" disabled={!isFormValid()} onClick={handlePhotoSave}>Сохранить</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default AddUserPhoto
