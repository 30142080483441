import React, { useState } from "react";
import "./styles.scss";
import { Button, Form, Modal } from "react-bootstrap";
import PropTypes from "prop-types";

const AddNotificationDialog = ({ onSave, onHide, show }) => {
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  const handleTitleChange = event => {
    setTitle(event.target.value);
  }

  const handleMessageChange = event => {
    setMessage(event.target.value);
  }

  const isFormValid = () => {
    return (
      title.length > 0 && message.length > 0
    )
  }

  const handleNotificationSave = () => {
    onSave({ title, message });
    onHide();
  }

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header>
        <span>Новое уведомление</span>
      </Modal.Header>

      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Заголовок</Form.Label>
            <Form.Control
              type="text"
              placeholder="Введите заголовок ..."
              value={title}
              onChange={handleTitleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Сообщение</Form.Label>
            <Form.Control
              type="text"
              as="textarea"
              placeholder="Введите сообщение ..."
              value={message}
              onChange={handleMessageChange}
            />
          </Form.Group>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outline-danger" onClick={onHide}>Закрыть</Button>
        <Button variant="success" disabled={!isFormValid()} onClick={handleNotificationSave}>Отправить</Button>
      </Modal.Footer>
    </Modal>
  )
}

AddNotificationDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSave: PropTypes.func,
}

export default AddNotificationDialog
