import React, { useEffect, useState } from "react";
import PageHeader from "../../components/page-header";
import { Button, Modal, Table } from "react-bootstrap";
import "./styles.scss";
import AddPresetDialog from "../../components/add-preset";
import { api, globalErrorHandler } from "../../index";
import { useDispatch } from "react-redux";

const PresetsPage = props => {
  const dispatch = useDispatch();
  const [presets, setPresets] = useState([]);
  const [categories, setCategories] = useState([]);
  const [showDetails, setShowDetails] = useState({ show: false, preset: null });
  const [deleteDialog, setDeleteDialog] = useState({ show: false, preset: null });

  useEffect(() => {
    fetchPresets();
    fetchCategories();
  }, [props]);

  const fetchPresets = () => {
    api().get("/presets").then(response => {
      setPresets(response.data);
      console.log()
    }).catch(error => dispatch(globalErrorHandler(error)));
  };

  const fetchCategories = () => {
    api().get("/categories").then(response => {
      setCategories(response.data);
    }).catch(error => dispatch(globalErrorHandler(error)));
  };

  const showPresetDetails = (show, preset) => {
    setShowDetails({ show, preset });
  }

  const handleActionClick = () => {
    showPresetDetails(true, null);
  };

  const createPreset = preset => {
    const formData = new FormData();
    formData.append("name", preset.name);
    formData.append("presetFile", preset.presetFile, preset.presetFile.name);
    formData.append("presetImage", preset.presetImage, preset.presetImage.name);
    formData.append("presetInitialImage", preset.presetInitialImage, preset.presetInitialImage.name);
    formData.append("categoryId", preset.categoryId);
    formData.append("isPro", preset.isPro);

    api().post("/presets", formData).then(response => {
      fetchPresets();
    }).catch(error => dispatch(globalErrorHandler(error)));
  };

  const getFileName = category => {
    const parts = category.split("/");
    return parts[parts.length - 1];
  }

  const editPreset = preset => {
    const formData = new FormData();
    formData.append("name", preset.name);
    preset.presetFile && formData.append("presetFile", preset.presetFile, preset.presetFile.name);
    preset.presetImage && formData.append("presetImage", preset.presetImage, preset.presetImage.name);
    preset.presetInitialImage && formData.append("presetInitialImage", preset.presetInitialImage, preset.presetInitialImage.name);
    formData.append("categoryId", preset.categoryId);
    formData.append("isPro", preset.isPro);

    api().put(`/presets/${preset.id}`, formData).then(response => {
      fetchPresets();
    }).catch(error => dispatch(globalErrorHandler(error)));
  };

  const handlePresetSave = preset => {
    if (!preset.id) createPreset(preset);
    else editPreset(preset);
  };

  const hideDeleteDialog = () => {
    setDeleteDialog({ show: false, preset: null });
  }

  const handleDeleteClick = (event, preset) => {
    event.stopPropagation();
    setDeleteDialog({ show: true, preset });
  }

  const deletePreset = () => {
    const { preset } = deleteDialog;
    api().delete(`/presets/${preset.id}`).then(response => {
      fetchPresets()
    }).catch(error => dispatch(globalErrorHandler(error)));
    hideDeleteDialog()
  }

  return (
    <div className="presets-root">
      {deleteDialog.show && (
        <Modal
          centered
          show={deleteDialog.show}
          onHide={hideDeleteDialog}
        >
          <Modal.Header>
            Удалить персет?
          </Modal.Header>
          <Modal.Footer>
            <Button onClick={hideDeleteDialog}>Нет</Button>
            <Button variant="outline-danger" onClick={deletePreset}>Да</Button>
          </Modal.Footer>
        </Modal>
      )}
      {showDetails.show && (
        <AddPresetDialog
          show={showDetails.show}
          preset={showDetails.preset}
          onHide={() => showPresetDetails(false, null)}
          categories={categories}
          onSave={handlePresetSave}
        />
      )}

      <PageHeader title="Пресеты" actionText="Добавить +" onActionClick={handleActionClick}/>
      <Table striped bordered hover>
        <thead>
        <tr>
          <th>#</th>
          <th align="left">Название</th>
          <th>Изображение</th>
          <th>Файл</th>
          <th>Категория</th>
          <th>Лайки</th>
          <th>Платный</th>
          <th>Действия</th>
        </tr>
        </thead>
        <tbody>
        {presets.map((preset, index) => (
          <tr onClick={() => showPresetDetails(true, preset)} key={preset.id}>
            <td>{index + 1}</td>
            <td align="left">{preset.name}</td>
            <td>
              <a
                href={preset.image}
                target="_blank"
                onClick={e => e.stopPropagation()}
              >
                {getFileName(preset.image)}
              </a>
            </td>
            <td>
              <a
                href={preset.file}
                target="_blank"
                onClick={e => e.stopPropagation()}
              >
                {getFileName(preset.file)}
              </a>
            </td>
            <td>{preset.categoryTitle}</td>
            <td>{preset.likes}</td>
            <td>{preset.free ? "Нет" : "Да"}</td>
            <td align="center">
              <Button
                variant="none"
                onClick={event => handleDeleteClick(event, preset)}
                style={{ display: 'flex', alignItems: 'center', padding: 0 }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#dc3545">
                  <path d="M0 0h24v24H0V0z" fill="none"/>
                  <path
                    d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zm2.46-7.12l1.41-1.41L12 12.59l2.12-2.12 1.41 1.41L13.41 14l2.12 2.12-1.41 1.41L12 15.41l-2.12 2.12-1.41-1.41L10.59 14l-2.13-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4z"/>
                  <path d="M0 0h24v24H0z" fill="none"/>
                </svg>
              </Button>
            </td>
          </tr>
        ))}
        </tbody>
      </Table>
    </div>
  )
};

export default PresetsPage
