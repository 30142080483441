import React, { useEffect, useState } from "react";
import axios from 'axios';
import "./styles.scss";
import moment from "moment";
import { Line } from "react-chartjs-2";
import { Form } from "react-bootstrap";
import { api, globalErrorHandler } from "../../index";
import { useDispatch } from "react-redux";

const UsersChart = props => {
  const dispatch = useDispatch();
  const [statistic, setStatistic] = useState([]);
  const [purchaseStatistic, setPurchaseStatistic] = useState([]);
  const [month, setMonth] = useState(moment().month());

  useEffect(() => {
    fetchUsersStatistics();
    fetchPurchaseStatistics();
  }, [month]);

  const fetchUsersStatistics = () => {
    api().get(`/statistics/users?month=${month}`)
      .then(response => {
        setStatistic(response.data);
      })
      .catch(error => dispatch(globalErrorHandler(error)));
  }

  const fetchPurchaseStatistics = () => {
    api().get(`/statistics/purchases?month=${month}`)
      .then(response => {
        setPurchaseStatistic(response.data);
      })
      .catch(error => dispatch(globalErrorHandler(error)));
  }

  const mappedData = React.useMemo(() => (
    {
      labels: statistic.map(item => moment().date(item.day).format("DD MMM")),
      datasets: [
        {
          label: 'Новые пользователи',
          data: statistic.map(item => ({ x: item.day, y: item.count })),
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
          ],
        },
        {
          label: 'Новые покупки',
          data: purchaseStatistic.map(item => ({ x: item.day, y: item.count })),
          backgroundColor: [
            'rgba(54, 162, 235, 0.2)',
          ],
        }
      ]
    }
  ), [statistic, purchaseStatistic]);

  const handleMonthChange = event => {
    setMonth(event.target.value);
  }

  return (
    <div className="users-chart-root">
      <div className="title">
        Пользователи
        <div style={{ flex: 1 }}/>
        <Form.Group>
          <Form.Control
            as="select"
            custom
            onChange={handleMonthChange}
            value={month}
          >
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(monthIndex => (
              <option
                value={monthIndex}
                key={monthIndex}
                id={monthIndex}
              >
                {moment().month(monthIndex).format("MMMM")}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </div>
      <div className="chart">
        <Line data={mappedData} height={100}/>
      </div>
    </div>
  )
}

export default UsersChart
