import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import "./styles.scss"
import { api, KEY_AUTH_TOKEN, KEY_IS_LOGGED_ID } from "../../index";
import { useDispatch } from "react-redux";
import { notifyUserAuthenticated } from "../../redux/actions";

const AuthPage = props => {
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");

  const handlePasswordChanged = event => {
    setPassword(event.target.value);
  }

  const handleUsernameChanged = event => {
    setUsername(event.target.value);
  }

  const isFormValid = () => {
    return password.length > 0 && username.length > 0;
  }

  const authenticate = () => {
    const formData = new FormData()
    formData.append("username", username);
    formData.append("password", password);
    api().post("/auth/login", formData).then(response => {
      localStorage.setItem(KEY_AUTH_TOKEN, response.data.message);
      localStorage.setItem(KEY_IS_LOGGED_ID, "1");
      dispatch(notifyUserAuthenticated(true));
    }).catch(error => {
      if (error.response != null) {
        console.log(error.response.data);
        if (error.response.data.status === 401) {
          alert("Не правильное имя пользователя или пароль!");
        }
      } else {
        console.error(error)
        alert("Что-то пошло не так!");
      }
    })
  }

  return (
    <div className="auth-page-root">
      <div className="title">Login</div>
      <Form>
        <Form.Group>
          <Form.Control
            type="text"
            placeholder="Username"
            onChange={handleUsernameChanged}
            value={username}
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            placeholder="Password"
            type="password"
            onChange={handlePasswordChanged}
            value={password}
          />
        </Form.Group>
      </Form>

      <Button variant="success" onClick={authenticate} disabled={!isFormValid()}>Login</Button>
    </div>
  )
}

export default AuthPage
