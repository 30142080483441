import React, { useState } from "react";
import "./styles.scss";
import Logo from "../../assets/images/app_logo.svg";
import { ListGroup, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import logoutIcon from "../../assets/images/logout.png";
import { useDispatch } from "react-redux";
import { notifyUserAuthenticated } from "../../redux/actions";
import { KEY_AUTH_TOKEN, KEY_IS_LOGGED_ID } from "../../index";

const MainMenu = props => {
  const dispatch = useDispatch();
  const [logoutDialog, setLogoutDialog] = useState({ show: false });

  const hideLogoutDialog = () => {
    setLogoutDialog({ show: false });
  }

  const showLogoutDialog = () => {
    setLogoutDialog({ show: true });
  }

  const logout = () => {
    localStorage.removeItem(KEY_AUTH_TOKEN);
    localStorage.setItem(KEY_IS_LOGGED_ID, "0");
    hideLogoutDialog();
    dispatch(notifyUserAuthenticated(false));
  }

  return (
    <div className="main-menu-root">
      {logoutDialog.show && (
        <Modal
          centered
          show={logoutDialog.show}
          onHide={hideLogoutDialog}
        >
          <Modal.Header>
            Выйти из админ панели?
          </Modal.Header>
          <Modal.Footer>
            <Button onClick={hideLogoutDialog}>Нет</Button>
            <Button variant="outline-danger" onClick={logout}>Да</Button>
          </Modal.Footer>
        </Modal>
      )}
      <img src={Logo} alt="LGRUM Logo"/>
      <div className="divider"/>
      <ListGroup defaultActiveKey="/">
        <ListGroup.Item action href="/" to="/" as={Link}>
          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z"/>
          </svg>
          Главная
        </ListGroup.Item>
        <ListGroup.Item action href="/categories" to="/categories" as={Link}>
          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
            <path d="M4 14h4v-4H4v4zm0 5h4v-4H4v4zM4 9h4V5H4v4zm5 5h12v-4H9v4zm0 5h12v-4H9v4zM9 5v4h12V5H9z"/>
            <path d="M0 0h24v24H0z" fill="none"/>
          </svg>
          Категории
        </ListGroup.Item>
        <ListGroup.Item action href="/presets" to="/presets" as={Link}>
          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path
              d="M21 19V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zM8.5 13.5l2.5 3.01L14.5 12l4.5 6H5l3.5-4.5z"/>
          </svg>
          Пресеты
        </ListGroup.Item>
        <ListGroup.Item action href="/user-edits" to="/user-edits" as={Link}>
          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/>
          </svg>
          Фото пользователей
        </ListGroup.Item>
        <ListGroup.Item action href="/users" to="/users" as={Link}>
          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
            <path
              d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/>
            <path d="M0 0h24v24H0z" fill="none"/>
          </svg>
          Пользователи
        </ListGroup.Item>
        <ListGroup.Item action href="/notifications" to="/notifications" as={Link}>
          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
            <path d="M0 0h24v24H0V0z" fill="none"/>
            <path
              d="M18 16v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2zm-5 0h-2v-2h2v2zm0-4h-2V8h2v4zm-1 10c1.1 0 2-.9 2-2h-4c0 1.1.89 2 2 2z"/>
          </svg>
          Уведомления
        </ListGroup.Item>
        <ListGroup.Item action as={Button} onClick={showLogoutDialog}>
          <img src={logoutIcon} alt=""/>
          Выход
        </ListGroup.Item>
      </ListGroup>
    </div>
  )
};

export default MainMenu
