import React from 'react';
import "./styles.scss"
import PageHeader from "../../components/page-header";
import MostLikedPresets from "../../components/most-liked";
import MostDownloadedPresets from "../../components/most-downloaded";
import UsersChart from "../../components/users-chart";

const MainPage = props => {
  return (
    <div className="main-page-root">
      <PageHeader title="Главная"/>

      <div className="users-chart-container">
        <UsersChart/>
      </div>

      <div className="presets-data-container">
        <MostLikedPresets/>

        <MostDownloadedPresets/>
      </div>
    </div>
  )
};

export default MainPage;
