import React, { useState, memo } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Form } from "react-bootstrap";
import { isEqual } from "lodash";
import "./styles.scss";
import TagsInput from "../tags-input";

const getFileName = category => {
  const parts = category?.split("/");
  if (parts == null || parts.length === 0) {
    return '';
  }
  return parts[parts.length - 1];
}

const AddCategoryDialog = ({ onSave, onHide, category, show }) => {
  const [categoryTitle, setCategoryTitle] = useState(category ? category.title : "");
  const [categoryImage, setCategoryImage] = useState(category ? { name: getFileName(category.image) } : null);
  const [categoryTags, setCategoryTags] = useState(category ? category.tags : []);

  const handleCategoryTitleChange = event => {
    setCategoryTitle(event.target.value);
  }

  const handleCategoryImageChange = event => {
    setCategoryImage(event.target.files[0]);
  }

  const isFormValid = () => {
    return (
      categoryTitle.length > 0 &&
      categoryImage != null
    )
  }

  const handleCategorySave = () => {
    onSave({
      id: category ? category.id : null,
      title: categoryTitle,
      image: categoryImage.constructor === File ? categoryImage : null,
      tags: categoryTags,
    });
    onHide();
  }

  const handleTagsChanged = tags => {
    setCategoryTags(tags);
  }

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header>
        <span>Детали категории</span>
      </Modal.Header>

      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Название категории</Form.Label>
            <Form.Control
              type="text"
              placeholder="Введите название ..."
              value={categoryTitle}
              onChange={handleCategoryTitleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.File
              custom
              type="file"
              accept=".png,.jpg,.jpeg"
              label={categoryImage ? categoryImage.name : "Выберите изображение"}
              onChange={handleCategoryImageChange}
            />
          </Form.Group>
          <Form.Group>
            <TagsInput
              selectedTags={categoryTags}
              onChange={handleTagsChanged}
            />
          </Form.Group>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outline-danger" onClick={onHide}>Закрыть</Button>
        <Button variant="success" disabled={!isFormValid()} onClick={handleCategorySave}>Сохранить</Button>
      </Modal.Footer>
    </Modal>
  )
}

AddCategoryDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  category: PropTypes.shape({
    title: PropTypes.string,
    image: PropTypes.string,
  })
}

function arePropsEqual(prevProps, nextProps) {
  return isEqual(prevProps, nextProps);
}

export default memo(AddCategoryDialog, arePropsEqual);
