import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import { Button } from "react-bootstrap";

const PageHeader = props => {
  const { title, actionText, onActionClick } = props;

  return (
    <div className="page-header-root">
      <span className="title">{title}</span>
      <div style={{ flex: 1 }}/>
      {actionText && (
        <Button variant="outline-success" onClick={onActionClick}>
          {actionText}
        </Button>
      )}
    </div>
  )
};

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  actionText: PropTypes.string,
  onActionClick: PropTypes.func,
}

PageHeader.defaultProps = {
  onActionClick: () => null
}

export default PageHeader;
