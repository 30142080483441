import React, { useEffect, useState } from "react";
import "./styles.scss";
import { Table } from "react-bootstrap";
import axios from "axios";
import { api, globalErrorHandler } from "../../index";
import { useDispatch } from "react-redux";

const MostLikedPresets = props => {
  const dispatch = useDispatch();
  const [presets, setPresets] = useState([]);

  useEffect(() => {
    fetchPresets()
  }, [props]);

  const fetchPresets = () => {
    api().get("/statistics/liked-presets")
      .then(response => setPresets(response.data))
      .catch(error => dispatch(globalErrorHandler(error)));
  }

  return (
    <div className="most-liked-root">
      <div className="title">Топ понравившиеся пресетов</div>
      <Table striped>
        <thead>
        <tr>
          <th>#</th>
          <th align="left">Название</th>
          <th>Категория</th>
          <th>Лайки</th>
        </tr>
        </thead>
        <tbody>
        {presets.map((preset, index) => (
          <tr key={preset.id}>
            <td>{index + 1}</td>
            <td align="left">{preset.name}</td>
            <td>{preset.categoryTitle}</td>
            <td align="center">{preset.likes}</td>
          </tr>
        ))}
        </tbody>
      </Table>
    </div>
  )
}

export default MostLikedPresets
