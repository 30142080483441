import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Form } from "react-bootstrap";
import { isEqual } from "lodash";
import "./styles.scss"

const getFileName = category => {
  const parts = category.split("/");
  return parts[parts.length - 1];
}

const AddPresetDialog = ({ onSave, onHide, preset, show, categories }) => {
  const [presetName, setPresetName] = useState(preset ? preset.name : "");
  const [presetFile, setPresetFile] = useState(preset ? { name: getFileName(preset.file) } : null);
  const [presetImage, setPresetImage] = useState(preset ? { name: getFileName(preset.image) } : null);
  const [presetOriginalImage, setPresetOriginalImage] =
    useState(preset ? { name: getFileName(preset.initialImage) } : null);
  const [isPro, setIsPro] = useState(Boolean(preset && !preset.free));
  const [presetCategory, setPresetCategory] = useState(preset ? {
    id: preset.categoryId,
    title: preset.categoryTitle
  } : categories.length > 0 ? categories[0] : null)

  const handlePresetFileChange = event => {
    setPresetFile(event.target.files[0]);
  };

  const handlePresetImageChange = event => {
    setPresetImage(event.target.files[0]);
  };

  const handlePresetOriginalImageChange = event => {
    setPresetOriginalImage(event.target.files[0]);
  }

  const handleProStatusChanged = event => {
    setIsPro(event.target.checked);
  }

  const handleCategoryChange = event => {
    const category = categories.find(category => category.title === event.target.value);
    console.log(category);
    setPresetCategory(category);
  }

  const handlePresetNameChange = event => {
    setPresetName(event.target.value);
  };

  const isFormValid = () => {
    return (presetName.length > 0 && presetCategory != null && true && presetImage != null && presetOriginalImage != null && presetFile != null)
  };

  const handlePresetSave = () => {
    onSave({
      id: preset ? preset.id : null,
      name: presetName,
      categoryId: presetCategory.id,
      presetFile: presetFile.constructor === File ? presetFile : null,
      presetImage: presetImage.constructor === File ? presetImage : null,
      presetInitialImage: presetOriginalImage.constructor === File ? presetOriginalImage : null,
      isPro,
    });
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header>
        <span>Детали пресета</span>
      </Modal.Header>

      <Modal.Body>
        <Form.Group>
          <Form.Label>Название пресета</Form.Label>
          <Form.Control
            type="text"
            placeholder="Введите название для пресета ..."
            onChange={handlePresetNameChange}
            value={presetName}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Выберите категорию</Form.Label>
          <Form.Control
            as="select"
            custom
            onChange={handleCategoryChange}
            value={presetCategory ? presetCategory.name : ""}
          >
            {categories.map(category => (
              <option
                key={category.id}
                id={category.id}
              >
                {category.title}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.File
            custom
            id="presetFile"
            accept=".dng"
            label={presetFile ? presetFile.name : "Выберите файл пресета"}
            onChange={handlePresetFileChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.File
            custom
            id="presetImage"
            accept=".png,.jpg,.jpeg"
            label={presetImage ? presetImage.name : "Выберите отредактированое изображение"}
            onChange={handlePresetImageChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.File
            custom
            accept=".png,.jpg,.jpeg"
            id="presetInitialImage"
            label={presetOriginalImage ? presetOriginalImage.name : "Выберите начальное изображение"}
            onChange={handlePresetOriginalImageChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Check
            onChange={handleProStatusChanged}
            checked={isPro}
            label="Платный пресет"
          />
        </Form.Group>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outline-danger" onClick={onHide}>Закрыть</Button>
        <Button variant="success" disabled={!isFormValid()} onClick={handlePresetSave}>Сохранить</Button>
      </Modal.Footer>
    </Modal>
  )
};

AddPresetDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  preset: PropTypes.object,
  categories: PropTypes.array.isRequired
};

function arePropsEqual(prevProps, nextProps) {
  return isEqual(prevProps, nextProps);
}

export default memo(AddPresetDialog, arePropsEqual);
